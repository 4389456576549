import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../components/MainPage.vue'
import PickTrack from '../components/PickTrack.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: {name:'picktrack'} },
    {
        path: '/main',name:'main',component:MainPage,
        //子路由
        children:[
            {
                path: 'picktrack',
                name:'picktrack',
                component:PickTrack
            }
        ]
        
    },
]

const router = new VueRouter({
    routes
})


export default router