import service from "@/utils/axios";

export default{
    login(userInfo){
        return service.get('/login',{params:userInfo});
        //return service.post('/loginSave',userInfo);
    },
    send_coordinate(path_arr){
        return service.post('/',path_arr,{responseType: 'blob'});
    },

}