<template>
    <div style="height: 100%;">
        <el-col :span="17" style="height: 100%;">
            <div id="allmap"></div>
        </el-col>
        
        <el-col :span="6" :offset="1">
           
            地图中心：
            <el-row>
                <el-col w:span="18"> <el-input v-model="lng" placeholder="请输入经度"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="18"> <el-input v-model="lat" placeholder="请输入纬度"></el-input></el-col>
                <el-col :span="6"><el-button type="primary" @click="setCenter">跳转</el-button></el-col>
            </el-row>
            <p></p>
            <el-row v-if="filedownload">
                <el-col :span="18"><a :href="downloadURL" download="coordinate.json">下载轨迹文件</a></el-col>
            </el-row>
            
            
        </el-col>
    </div>
        
 
        
</template>
<script src="https://code.bdstatic.com/npm/mapvgl@1.0.0-beta.188/dist/mapvgl.min.js"></script>

<script>
//import { BMapGL} from '../plugins/mapvgl.min.js' //注意路径
import user from '@/api/user' 
export default {
    name: 'PickTrack',
    data() {
        return {
            bmapgl:false, //地图本体
            view:false,   // 图层管理器
            layer:false,  // 可视化图层
            data: [],     // 规范化坐标数据
            path_data:[],  //要发送给后端的数据
            menu:false, //菜单
            lng:'',//经度
            lat:'',//纬度
            filedownload:false,//是否有文件可下载
            downloadURL:''
        }
    },
    computed:{
        center_point:function(){//地图中心
            return new BMapGL.Point( this.lng,this.lat);
        }
    },
    methods: {
        setCenter(){
            this.bmapgl.setCenter(this.center_point);
        },
        initMap() {
            this.bmapgl = new BMapGL.Map("allmap");    // 创建Map实例
            this.lng = 117.092511
            this.lat = 30.507437
            this.bmapgl.centerAndZoom(this.center_point, 17);  // 初始化地图,设置中心点坐标和地图级别
            this.bmapgl.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放

            this.clickTrack();
            this.clickMenu();
        },
        clickTrack(){
            var center_point = this.center_point
            //图层管理器
            this.view = new mapvgl.View({
                map: this.bmapgl
            });

            //创建可视化图层，并添加到图层管理器中
            this.layer = new mapvgl.PointLayer({
                color: 'rgba(30, 30, 30, 1)',
                blend: 'lighter',
                size: 10
            });
            this.view.addLayer(this.layer);

            //准备好规范化坐标数据
            this.data = [];
            this.path_data=[];//要发送给后端的数据

            //起点入队
            this.data.push({
                geometry: {
                    type: 'Point',
                    coordinates: [center_point.lng, center_point.lat]
                }
            });

            this.path_data.push([center_point.lng, center_point.lat]);
            this.layer.setData(this.data);
            var that = this;
            console.log(this);
            this.bmapgl.addEventListener('click', function(e) {
                console.log('点击的经纬度：' + e.latlng.lng + ', ' + e.latlng.lat);
                
                that.data.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [e.latlng.lng, e.latlng.lat]
                    }
                });
                that.path_data.push([e.latlng.lng, e.latlng.lat]);
                that.layer.setData(that.data);
                that.bmapgl.setCenter(new BMapGL.Point(e.latlng.lng, e.latlng.lat));
            });
        },
        clickMenu(){
            this.menu = new BMapGL.ContextMenu();                // 创建右键菜单
            var that = this //vue实例
            var txtMenuItem = [
                {
                    text:'放大',                             // 定义菜单项的显示文本
                    callback: function () {                 // 定义菜单项点击触发的回调函数
                        that.bmapgl.zoomIn();
                    }
                },
                {
                    text:'缩小',
                    callback: function () {
                        that.bmapgl.zoomOut();
                    }
                },
                {
                    text:'撤销一个点',
                    callback: function () {
                        that.data.pop();
                        that.path_data.pop();
                        that.layer.setData(that.data);
                    }
                },
                {
                    text:'生成轨迹坐标集',
                    callback: function () {
                        //把数组转换为json字符串
                        //如果直接发送数组，会将数组元素拼接为字符串
                        var topydata = JSON.stringify(that.path_data);
                        console.log(topydata);

                        //构建表单
                        const formData = new FormData();
                        //发送数据
                        formData.append('coordinate', topydata);
                        user.send_coordinate(formData).then(res=>{
                            console.log(res)
                            let blob = new Blob([res.data], {type: 'application/octet-stream'}); //这一步是关键
                            that.downloadURL=URL.createObjectURL(blob)
                            that.filedownload=true
                           
                        })
                        
                    } 
                }
                
            ];
            for(var i = 0; i < txtMenuItem.length; i++){
                this.menu.addItem(new BMapGL.MenuItem(               // 定义菜单项实例
                    txtMenuItem[i].text,                        // 传入菜单项的显示文本
                    txtMenuItem[i].callback,                    // 传入菜单项的回调函数
                    {
                        width: 300,                             // 指定菜单项的宽度
                        id: 'menu' + i                          // 指定菜单项dom的id
                    }
                ));
            }
            this.bmapgl.addContextMenu(this.menu);    
        },
        insertjs(){
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://code.bdstatic.com/npm/mapvgl@1.0.0-beta.188/dist/mapvgl.min.js';
            document.head.appendChild(script);
        },
    },
 
    mounted() {
        this.initMap();
    }
}
</script>

<style scoped>
#allmap {width: 100%;height: 100%;overflow: hidden;margin:0;font-family:"微软雅黑";}
</style>